import * as React from "react"
import { Link } from 'gatsby'

const CTAbuttonsNew = ({ withButton, twoButtons, buttonOneTypeHover, buttonOneName, buttonOneNameHover, buttonOneLinkType, buttonOneLinkUrl, buttonOneLinkUid, buttonTwoTypeHover, buttonTwoName, buttonTwoNameHover, buttonTwoLinkType, buttonTwoLinkUrl, buttonTwoLinkUid }) => {
  return (
    withButton
      ?
      <>
        {twoButtons
          ?
          <div className="flex flex-col xl:flex-row items-center justify-center mt-6 xl:mt-8 md:space-x-6">
            {buttonOneTypeHover
              ?
              <>
                <div className="hidden xl:block">
                  {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                    ?
                    <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                      <button className="w-full bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red uppercase tracking-wider px-11 py-3 mb-6 md:mb-0 mx-auto">
                        <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                        <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                      </button>
                    </Link>
                    :
                    buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                    ?
                    <a
                      href={buttonOneLinkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red uppercase tracking-wider px-11 py-3 mb-6 md:mb-0 mx-auto">
                        <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                        <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                      </button>
                    </a>
                    :
                    null
                  }
                </div>

                <div className="block xl:hidden mt-6 mb-6">
                  <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{buttonOneName ? buttonOneName : "Button Name"}</p>
                    {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                      ?
                      <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                        <button className="w-full md:w-auto bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red uppercase tracking-wider px-11 py-3">
                          <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                        </button>
                      </Link>
                      :
                      buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                      ?
                      <a
                        href={buttonOneLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="w-full md:w-auto bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red uppercase tracking-wider px-11 py-3">
                          <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                        </button>
                      </a>
                      :
                      null
                    }
                </div>
              </>
              :
              <>
                {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                  ?
                  <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-white uppercase tracking-wider px-11 py-3">
                      {buttonOneName ? buttonOneName : "Button Name"}
                    </button>
                  </Link>
                  :
                  buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                  ?
                  <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-whites uppercase tracking-wider px-11 py-3">
                      {buttonOneName ? buttonOneName : "Button Name"}
                    </button>
                  </Link>
                  :
                  null
                }
              </>
            }

            {buttonTwoTypeHover
              ?
              <>
                <div className="hidden xl:block">
                  {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                    ?
                    <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                      <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6 mx-auto">
                        <span className="default-text mx-2">{buttonTwoName ? buttonTwoName : "Button Name"}</span>
                        <span className="hover-text">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                      </button>
                    </Link>
                    :
                    buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                    ?
                    <a
                      href={buttonTwoLinkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mb-6">
                        <span className="default-text mx-2">{buttonTwoName ? buttonTwoName : "Button Name"}</span>
                        <span className="hover-text">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                      </button>
                    </a>
                    :
                    null
                  }
                </div>

                <div className="block xl:hidden mt-6 mb-6">
                  <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{buttonTwoName ? buttonTwoName : "Button Name"}</p>
                    {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                      ?
                      <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                          <span className="mx-1">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                        </button>
                      </Link>
                      :
                      buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                      ?
                      <a
                        href={buttonTwoLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                          <span className="mx-1">{buttonTwoNameHover ? buttonTwoNameHover : "Button Name Hover"}</span>
                        </button>
                      </a>
                      :
                      null
                    }
                </div>
              </>
              :
              <>
                {buttonTwoLinkType === "Document" || buttonTwoLinkType === "Any"
                  ?
                  <Link to="/#831b-plans">
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red tracking-wider px-7 md:px-8 py-3">
                      WHAT IS AN 831(b) PLAN?
                    </button>
                  </Link>
                  :
                  buttonTwoLinkType === "Web" || buttonTwoLinkType === "Media"
                  ?
                  <Link to={buttonTwoLinkUid ? `/${buttonTwoLinkUid}` : "/"}>
                    <button className="w-full mb-4 xl:mb-0 md:w-auto bg-white opacity-90 hover:opacity-100 text-base md:text-lg text-site-red uppercase tracking-wider px-9 md:px-8 py-3">
                      {buttonTwoName ? buttonTwoName : "Button Name"}
                    </button>
                  </Link>
                  :
                  null
                }
              </>
            }
          </div>
          :
          <div className="w-full flex items-center justify-center mt-8">
            {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
              ?
                <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                  {buttonOneTypeHover
                    ?
                    <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-11 py-3 mb-6">
                      <span className="default-text">{buttonOneName ? buttonOneName : "Button Name"}</span>
                      <span className="hover-text mx-7">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                    </button>
                    :
                    <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
                      {buttonOneName ? buttonOneName : "Button Name"}
                    </button>
                  }
                </Link>
              :
              buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
              ?
                <a
                  href={buttonOneLinkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-11 py-3">
                  {buttonOneName ? buttonOneName : "Button Name"}
                  </button>
                </a>
              :
              null
            }
          </div>
        }
      </>
      :
      null
  )
}

export default CTAbuttonsNew
